import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    
  }
}

// delete Wordpress2016.googleFonts

const typography = new Typography( {
  googleFonts: [
    {
      name: 'Montserrat',
      styles: [
        '700',
      ],
    },
    {
      name: 'Mukta',
      styles: [
        '400',
        '400i',
        '700',
        '700i',
      ],
    },
  ],  
  headerFontFamily: ["Montserrat"],
  bodyFontFamily: ["Mukta"]
},
  Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
